<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false">
    </loading>
    <b-alert v-model="flagTransactionFail" variant="danger" dismissible>
      {{errMsg}}
    </b-alert>  
    <b-row>
      <b-col lg="10" offset-lg="1">
        <b-row>      
          <!-- Shipment info -->
          <b-col lg="7">  
            <CCard 
              class="mb-0"
              style="margin-top:0.5rem;">
              <CButton 
                block 
                color="link" 
                class="text-left shadow-none card-header" 
                @click="accordionShipmentData = accordionShipmentData === 0 ? false : 0" 
              >
                <h5 class="m-0">Datos de entrega</h5>
              </CButton>
              <CCollapse :show="accordionShipmentData === 0">
                <CCardBody>
                  <b-row>
                    <!-- fullname -->
                    <b-col sm="6">
                      <b-form-group>
                        <label for="firstName"><strong>Nombres y apellidos<small class="alert-text"> *</small></strong></label>
                        <b-form-input
                            type="text"
                            id="firstName"
                            v-model.trim="$v.shipmentData.fullname.$model"
                            placeholder="Inserta tus nombres"
                            :class="{'form-control is-invalid': $v.shipmentData.fullname.$error,'form-control is-valid': !$v.shipmentData.fullname.$invalid}">
                        </b-form-input>
                        <b-form-invalid-feedback v-if="!$v.shipmentData.fullname.required">
                            Tu nombre es necesario
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="!$v.shipmentData.fullname.minLength">
                            El primer nombre debe tener como mínimo {{$v.shipmentData.fullname.$params.minLength.min}} letras
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <!-- city -->
                    <b-col sm="6">
                      <b-form-group>
                        <label for="city">
                          <strong>
                              Ciudad destino<small class="alert-text">*</small>
                          </strong>
                          
                        </label>                    
                        <vue-google-autocomplete
                          v-model.trim="$v.shipmentData.city.$model"
                          id="city"
                          ref="city"
                          classname="form-control"
                          :class="{'form-control is-invalid': $v.shipmentData.city.$error,
                                  'form-control is-valid': !$v.shipmentData.city.$invalid}"
                          placeholder="Busca tu ciudad con Google Maps"
                          v-on:placechanged="getAddressData"
                          v-on:no-results-found="cityDataNotFound"
                          v-on:inputChange="cityDataChanged"
                          v-on:blur="cityBlur"
                          autocomplete="off"                        
                          types="(cities)">
                        </vue-google-autocomplete>  
                        <small class="form-text text-muted w-100">
                          Busca tu ciudad y selecciona la que coincida     
                        </small>          
                        <b-form-invalid-feedback v-if="!$v.shipmentData.city.required || !$v.shipmentData.city.minLength">
                          Tu ciudad de residencia es necesaria, busca y selecciona las opciones de Google
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- Address -->
                    <b-col lg="12">
                      <b-form-group>
                        <label for="address">
                          <strong>
                            Dirección de entrega <small class="alert-text">*</small>
                          </strong>
                          <small class="form-text text-muted w-100">
                          Ingresa la dirección de entrega y barrio.
                          </small>                    
                        </label>                    
                        <b-form-input 
                          type="text"
                          id="address"
                          v-model.trim="$v.shipmentData.address.$model"
                          :class="{'form-control is-invalid': $v.shipmentData.address.$error,
                                  'form-control is-valid': !$v.shipmentData.address.$invalid}">
                        </b-form-input>                    
                        <b-form-invalid-feedback v-if="!$v.shipmentData.address.required">
                            Tu dirección es necesaria
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Email -->
                    <b-col lg="6">
                      <label for="contact_email">
                          <strong>Tu email de contacto</strong>
                          <small class="alert-text"> *</small>
                      </label>
                      <b-form-group>                                       
                        <b-form-input 
                          type="text"
                          id="email"
                          v-model.trim="$v.shipmentData.email.$model"                      
                          :class="{'form-control is-invalid': $v.shipmentData.email.$error,
                          'form-control is-valid': !$v.shipmentData.email.$invalid}">
                        </b-form-input>
                        <b-form-invalid-feedback v-if="!$v.shipmentData.email.required">
                          Tu email de contacto es necesario
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="!$v.shipmentData.email.email">
                          Tu email de contacto no parece valido
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- phone number -->
                    <b-col md="6" >
                      <b-form-group>                          
                        <label for="Phone"><strong>Tu número de teléfono <small class="alert-text">*</small></strong></label>
                        <vue-tel-input 
                          v-model.trim="$v.shipmentData.phone_number.$model"
                          placeholder="Tu número de teléfono"
                          :class="{
                            'form-control is-invalid': $v.shipmentData.phone_number.$error,
                            'form-control is-valid': !$v.shipmentData.phone_number.$invalid }"
                            v-bind="phoneNumberProps">
                        </vue-tel-input>
                        <b-form-invalid-feedback v-if="!$v.shipmentData.phone_number.minLength">
                            El número de teléfono debe tener como mínimo {{$v.shipmentData.phone_number.$params.minLength.min}} números.
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="!$v.shipmentData.phone_number.phoneNumber">
                            Debe tener solo números.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>    
                  </b-row>
                  <b-row>
                    <!-- To do insert button to go to the payment section -->
                  </b-row>
                </CCardBody>
              </CCollapse>
            </CCard>
          </b-col>

          <!-- Resumen -->
          <b-col lg=5>
            <CCard 
              class="mb-0"
              style="margin-top:0.5rem;">
              <CButton 
                block 
                color="link" 
                class="text-left shadow-none card-header" 
                @click="accordionResumen = accordionResumen === 0 ? false : 0" 
              >
                <h5 class="m-0">Resumen de tu compra</h5>
              </CButton>
              <CCollapse :show="accordionResumen === 0">
                <CCardBody>
                  <b-row>
                    <b-col lg="12">
                      <strong>{{itemFeatures.name}}</strong> 
                    </b-col>                    

                    <b-col lg="8" style="text-align:right;">
                      Modelo: 
                    </b-col>
                    <b-col lg="4" style="text-align:left;">
                      {{itemFeatures.model}}
                    </b-col>

                    <b-col lg="8" style="text-align:right;">
                      Color: 
                    </b-col>
                    <b-col lg="4" style="text-align:left;">
                      {{itemFeatures.color}}
                    </b-col>
                    
                    <b-col lg="8" style="text-align:right;">
                      Tamaño: 
                    </b-col>
                    <b-col lg="4" style="text-align:left;">
                      {{itemFeatures.size}}
                    </b-col>
                                       
                    <b-col lg="8" style="text-align:right;">
                      Cantidad: 
                    </b-col>
                    <b-col lg="4" style="text-align:left;">
                      {{itemFeatures.quantity}}
                    </b-col>

                    <b-col lg="8" style="text-align:right;">
                      Precio: 
                    </b-col>
                    <b-col lg="4" style="text-align:left;">
                      {{itemFeatures.currencyCop}}
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row>
                    <b-col lg="8" style="text-align:right;">
                      <strong>Total: </strong> 
                    </b-col>
                    <b-col lg="4" style="text-align:left;">
                      <strong>{{totalPrice}}</strong> 
                    </b-col>
                  </b-row>
                </CCardBody>
              </CCollapse>
            </CCard>
          </b-col>
                    
          <!-- methods of paymen -->
          <b-col lg="7">
            <CCard 
              class="mb-0" 
              style="margin-top:0.5rem;">
              <CButton 
                block 
                color="link" 
                class="text-left shadow-none card-header" 
                @click="accordionPayments = accordionPayments === 0 ? false : 0" 
              >
                <h5 class="m-0">Selecciona tu medio de pago</h5>
              </CButton>
              <CCollapse :show="accordionPayments === 0">
                <CCardBody>
                  <b-row v-if="$v.shipmentData.$invalid">                
                    <b-col lg="12">
                      <CAlert show color="secondary">
                        Por favor ingresa los <strong>datos de entrega</strong>  que te solicitamos.
                      </CAlert>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col lg="12">
                      <CTabs 
                        variant="pills" 
                        vertical
                        @update:activeTab="logTabs">
                        <CTab active>
                          <template slot="title">                            
                            <img src="@/assets/images/pse.png" class="c-sidebar-brand-minimized" height="25px"/>                                  
                            {{tabs[0]}}                                                      
                          </template>
                            <b-row>
                              <!-- Bank -->
                              <b-col lg="12">
                                <b-form-group>
                                  <small>Tu banco</small>                                  
                                  <v-select 
                                    v-model="paymentPSE.bankSelected"
                                    :options="banks" 
                                    placeholder="Selecciona tu banco"
                                   :class="{'form-control is-invalid': $v.paymentPSE.bankSelected.$error,
                                                 'form-control is-valid': !$v.paymentPSE.bankSelected.$invalid}">
                                  </v-select>  
                                  <b-form-invalid-feedback v-if="!$v.paymentPSE.bankSelected.required">
                                    Debes seleccionar un banco
                                  </b-form-invalid-feedback>     
                                </b-form-group>
                              </b-col>
                              <!-- <b-form-group
                                  label="Banco *"
                                  label-for="bank"                        
                                  :label-cols="4">
                                  <b-form-select 
                                      id="bank"                                       
                                      v-model="bankSelected" 
                                      :options="banks"
                                      :class="{
                                          'form-control is-invalid': $v.bankSelected.$error,
                                          'form-control is-valid': !$v.bankSelected.$invalid}">
                                  </b-form-select>
                                  <b-form-invalid-feedback v-if="!$v.bankSelected.required">
                                      Debes seleccionar un banco
                                  </b-form-invalid-feedback>                        
                              </b-form-group>  
                                                                           -->
                              <!-- Client Type -->
                              <b-col lg="12">
                                <b-form-group>   
                                  <small>Tipo de persona</small>                        
                                  <b-form-select 
                                      id="bank" 
                                      v-model="paymentPSE.clientTypeSelected" 
                                      :options="clientType"
                                      :class="{'form-control is-invalid': $v.paymentPSE.clientTypeSelected.$error,
                                          'form-control is-valid': !$v.paymentPSE.clientTypeSelected.$invalid}">
                                  </b-form-select>
                                  <b-form-invalid-feedback v-if="!$v.paymentPSE.clientTypeSelected.required">
                                      Debes seleccionar un tipo de cliente
                                  </b-form-invalid-feedback>  
                                </b-form-group> 
                              </b-col>
                                
                              <!-- Type Document and doc number-->
                              <b-col lg="12">
                                <b-form-group>
                                  <b-row>
                                      <b-col lg="4">
                                          <small>Tipo</small>
                                          <b-form-select                                 
                                              id="typeDoc" 
                                              v-model="paymentPSE.typeDocSelected" 
                                              :options="typeDocs"
                                              :class="{'form-control is-invalid': $v.paymentPSE.typeDocSelected.$error,
                                                  'form-control is-valid': !$v.paymentPSE.typeDocSelected.$invalid}">
                                          </b-form-select>
                                          <b-form-invalid-feedback v-if="!$v.paymentPSE.typeDocSelected.required">
                                              Debes seleccionar un tipo de documento
                                          </b-form-invalid-feedback> 
                                      </b-col>
                                      <b-col lg="8">
                                        <small>Número de documento</small>
                                        <b-form-input
                                          type="number"
                                          id="numDoc"
                                          v-model.trim="$v.paymentPSE.numDocument.$model"
                                          placeholder="Número de documento"
                                          :class="{'form-control is-invalid': $v.paymentPSE.numDocument.$error,
                                              'form-control is-valid': !$v.paymentPSE.numDocument.$invalid}">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="!$v.paymentPSE.numDocument.required">
                                          Tú número de documento es obligatorio
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback v-if="!$v.paymentPSE.numDocument.minLength">
                                          El documento debe tener como mínimo {{$v.paymentPSE.numDocument.$params.minLength.min}} dígitos
                                        </b-form-invalid-feedback>
                                      </b-col>
                                  </b-row>                                                                    
                                </b-form-group> 
                              </b-col>

                              <b-col 
                                lg="12" 
                                style="margin-bottom:0.5rem">
                                Al presionar <strong>Pagar</strong> aceptas los
                                <a v-on:click="goTo('terms')">
                                  <span class="text-link-color">
                                    Términos y Condiciones
                                  </span>
                                </a>
                              </b-col>

                              <b-col 
                                lg="12" 
                                md="12">
                                  <b-button
                                  class="s-bg s-bg-text mr-1 w-100"
                                  @click="submit">
                                    Pagar
                                  </b-button>
                              </b-col>                              
                            </b-row>
                        </CTab>

                        <!-- payment debit card -->
                        <CTab>
                          <template slot="title">
                            <CIcon name="cil-credit-card"/> {{tabs[1]}}
                          </template>

                          <b-row>
                            <!-- Doc. Number -->
                            <b-col lg="12">
                              Número de documento *
                              <b-form-input
                                type="number"
                                id="numDoc"
                                v-model.trim="$v.paymentDebitCard.numDocument.$model"
                                placeholder="Número de documento"
                                :class="{'form-control is-invalid': $v.paymentDebitCard.numDocument.$error,
                                    'form-control is-valid': !$v.paymentDebitCard.numDocument.$invalid}">
                              </b-form-input>
                              <b-form-invalid-feedback v-if="!$v.paymentDebitCard.numDocument.required">
                                Tú número de documento es obligatorio
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback v-if="!$v.paymentDebitCard.numDocument.minLength">
                                El documento debe tener como mínimo {{$v.paymentDebitCard.numDocument.$params.minLength.min}} dígitos
                              </b-form-invalid-feedback>
                            </b-col>
                          </b-row>
                          <hr>
                          <b-row>                                                        
                            <!-- Card -->
                            <b-col lg="12" class="text-center">  
                              <b-form-group>                                
                                <b-form-radio-group
                                v-model="paymentDebitCard.cardSelected"
                                :options="debitCardOptions"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                disabled-field="notEnabled"
                                :class="{'form-control is-invalid': $v.paymentDebitCard.cardSelected.$error,
                                    'form-control is-valid': !$v.paymentDebitCard.cardSelected.$invalid}">                                                       
                                </b-form-radio-group>                                                    
                                <b-form-invalid-feedback v-if="!$v.paymentDebitCard.cardSelected.required">
                                    Debes seleccionar el tipo de tarjeta
                                </b-form-invalid-feedback> 
                              </b-form-group>                            
                            </b-col> 

                            <!-- Card Number -->                            
                            <b-col sm="12">                       
                              <b-form-group>
                                Número de la tarjeta *
                                <b-form-input
                                  type="number"
                                  id="cardNumber"
                                  v-model.trim="$v.paymentDebitCard.cardNumber.$model"
                                  :class="{'form-control is-invalid': $v.paymentDebitCard.cardNumber.$error,
                                      'form-control is-valid': !$v.paymentDebitCard.cardNumber.$invalid }"
                                  placeholder="0000 0000 0000 0000">
                                </b-form-input>                            
                                <b-form-invalid-feedback v-if="!$v.paymentDebitCard.cardNumber.required">
                                    El número de la tarjeta es obligatorio
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.paymentDebitCard.cardNumber.minLength">
                                    El número de la tarjeta debe tener {{$v.paymentDebitCard.cardNumber.$params.minLength.min}} números
                                </b-form-invalid-feedback>
                              </b-form-group> 
                            </b-col>

                            <b-col lg="12">                              
                              <b-form-group>
                                Nombre en la tarjeta *
                                <b-form-input
                                    type="text"
                                    id="cardName"
                                    placeholder="Inserta el nombre que aparece en la tarjeta"
                                    v-model.trim="$v.paymentDebitCard.cardName.$model"
                                    :class="{'form-control is-invalid': $v.paymentDebitCard.cardName.$error,
                                        'form-control is-valid': !$v.paymentDebitCard.cardName.$invalid}">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="!$v.paymentDebitCard.cardName.required">
                                    Tu nombre en la tarjeta es obligatorio
                                </b-form-invalid-feedback>                       
                              </b-form-group>                         
                            </b-col>

                            <!-- Expiration Month -->
                            <b-col sm="4">
                                <b-form-group >
                                    <label for="month1">Mes (expiración)</label>
                                    <b-form-select id="monthExpire"
                                    :plain="true"
                                    :options="['01','02','03','04','05','06','07','08','09','10','11','12']"
                                    v-model="paymentDebitCard.expirationMonth" 
                                    :class="{'form-control is-invalid': $v.paymentDebitCard.expirationMonth.$error,
                                        'form-control is-valid': !$v.paymentDebitCard.expirationMonth.$invalid}">
                                    </b-form-select>
                                    <b-form-invalid-feedback v-if="!$v.paymentDebitCard.expirationMonth.required">
                                        El mes de expiración es obligatorio.
                                    </b-form-invalid-feedback> 
                                </b-form-group>
                            </b-col>
                            <!-- Expiration Year -->
                            <b-col sm="4">
                                <b-form-group>
                                    <label for="year1">Año (expiración)</label>
                                    <b-form-select id="yearExpire"
                                    :plain="true"
                                    :options="[2021,2022,2023,2024,2025,2026,2027,2028,2029,2030]"
                                    v-model="paymentDebitCard.expirationYear"
                                    :class="{'form-control is-invalid': $v.paymentDebitCard.expirationYear.$error,
                                        'form-control is-valid': !$v.paymentDebitCard.expirationYear.$invalid}">
                                    </b-form-select>
                                    <b-form-invalid-feedback v-if="!$v.paymentDebitCard.expirationYear.required">
                                        El año de expiración es obligatorio.
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <!-- Secret Code -->
                            <b-col sm="4">
                                <b-form-group>
                                    <label for="cvv">CVV/CVC</label>
                                    <b-form-input 
                                        type="number" 
                                        id="cvv" 
                                        placeholder="123"
                                        v-model.trim="$v.paymentDebitCard.cvv.$model"
                                        :class="{'form-control is-invalid': $v.paymentDebitCard.cvv.$error,
                                            'form-control is-valid': !$v.paymentDebitCard.cvv.$invalid}">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="!$v.paymentDebitCard.cvv.required">
                                        El código de seguridad es obligatorio.
                                    </b-form-invalid-feedback> 
                                </b-form-group>
                            </b-col> 

                            <b-col 
                              lg="12" 
                              style="margin-bottom:0.5rem">
                              Al presionar <strong>Pagar</strong> aceptas los
                              <a v-on:click="goTo('terms')">
                                <span class="text-link-color">
                                  Términos y Condiciones
                                </span>
                              </a>
                            </b-col>                               

                            <b-col 
                              lg="12" 
                              md="12">
                                <b-button
                                class="s-bg s-bg-text mr-1 w-100"
                                @click="submit">
                                  Pagar
                                </b-button>
                            </b-col>                  

                          </b-row>
                        </CTab>



                        <!-- <CTab>
                          <template slot="title">
                            <CIcon name="cil-chart-pie"/> {{tabs[2]}}
                          </template>
                          3. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                          officia deserunt mollit anim id est laborum.
                        </CTab> -->
                      </CTabs>
                    </b-col>
                  </b-row>
                  
                </CCardBody>
              </CCollapse>
            </CCard>   
          </b-col>
                                        
        </b-row>
      </b-col>
    </b-row>
    
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { required, minLength, email, requiredUnless, requiredIf } from 'vuelidate/lib/validators';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
const phoneNumber = (number) => {
  if (!!number) {
    return !!number.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)
  } else {
    return false;
  }
}
export default {
  name: "buy",
  components: {
    Loading,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      loading: true,
      userProfile: null,
      collapse: false,
      cardCollapse: true,
      innerCollapse: false,
      accordion: 0,
      accordionShipmentData: 0,
      accordionResumen: 0,
      accordionPayments: 0,
      phoneNumberProps:{
        mode: "international",
        defaultCountry: "CO",
        enabledCountryCode: true,
      },
      shipmentData: {
        fullname: null,
        city: null,
        address: null,
        email: null,
        phone_number: null,
      },
      itemFeatures: null,
      // payment data
      tabs: [
        'PSE',
        'Tarjeta débito',
        'Tarjeta crédito'
      ],      
      banks: [
        { value: null, label: 'SELECCIONE' },
        { value: '1059', label: 'BANCAMIA S.A.' },
        { value: '1040', label: 'BANCO AGRARIO' },           
      ],
      clientType: [
        { value: null, text: 'SELECCIONE' },
        { value: 'N', text: 'Persona natural' },
        { value: 'J', text: 'Persona jurídica' },                                
      ],
      typeDocs: [
        { value: null, text: 'SELECCIONE' },
        { value: 'CC', text: 'CC - Cédula de ciudadanía' },
        { value: 'CE', text: 'CE - Cédula de extranjería.' },                                
        { value: 'NIT', text: 'NIT - Número de Identificación Tributario' },
        { value: 'TI', text: 'TI - Tarjeta de Identidad' },
        { value: 'PP', text: 'PP - Pasaporte' },                                
        { value: 'IDC', text: 'IDC - Identificador único de cliente, para el caso de ID’s únicos de clientes/usuarios de servicios públicos' },
        { value: 'CEL', text: 'CEL - En caso de identificarse a través de la línea del móvil' },
        { value: 'RC', text: 'RC - Registro civil de nacimiento' },                                
        { value: 'DE', text: 'DE - Documento de identificación extranjero' },                                
      ],
      debitCardOptions:[
        {item: 'VISA', name: 'Visa'},
        {item: 'MASTERCARD', name: 'Mastercard'}
      ],
      paymentPSE: {
        bankSelected: null,
        clientTypeSelected: null,
        typeDocSelected: null,
        numDocument:null,
      }, 
      paymentDebitCard: {
        ////Prod
        cardSelected: null,
        cardNumber: null,
        cardName: null,
        cvv: null,
        expirationMonth: null,
        expirationYear: null,        

        //// test
        // numDocument: '1061726121',
        // cardSelected: 'VISA',   
        // cardNumber: '4509420000000008',
        // cardName: 'APPROVED',
        // cvv: '777',
        // expirationMonth: '04',
        // expirationYear: '2023',       
      },    
      flagPSE: false,
      flagDebitCard: false,  
      userCurrency: 'COP',
      errMsg: '',
      flagTransactionFail: false,
    }
  },
  validations: {
    shipmentData: {
      fullname: {
        required,
        minLength: minLength(2)
      },
      city: {
        required,
        minLength: minLength(4)
      },
      address: {
        required
      },
      email:{
        required,
        email,
      },
      phone_number: {
        required,
        minLength: minLength(7),
        phoneNumber,
      },      
    },
    paymentPSE: {
      bankSelected: {            
        required: requiredIf(function(){
          if(this.flagPSE){
            return true
          }else{
            return false
          }
        })        
      },
      clientTypeSelected: {
        required: requiredIf(function(){
          if(this.flagPSE){
            return true
          }else{
            return false
          }
        }) 
      },
      typeDocSelected: {
        required: requiredIf(function(){
          if(this.flagPSE){
            return true
          }else{
            return false
          }
        }) 
      },
      numDocument: {
        required: requiredIf(function(){
          if(this.flagPSE){
            return true
          }else{
            return false
          }
        }),
        minLength: minLength(8)
      }
    },
    paymentDebitCard: {
      numDocument: {
        required: requiredIf(function(){
          if(this.flagPSE){
            return true
          }else{
            return false
          }
        }),
        minLength: minLength(8)
      },
      cardSelected: {
        required: requiredIf(function(){
          if(this.flagDebitCard){
            return true
          }else{
            return false
          }
        }) 
      },
      cardNumber: {
        required: requiredIf(function(){
          if(this.flagDebitCard){
            return true
          }else{
            return false
          }
        }),
        minLength: minLength(1),
      },
      cardName: {
        required: requiredIf(function(){
          if(this.flagDebitCard){
            return true
          }else{
            return false
          }
        }),
      },
      cvv: {
        required: requiredIf(function(){
          if(this.flagDebitCard){
            return true
          }else{
            return false
          }
        }),
        minLength: minLength(3)
      },
      expirationMonth:{
        required: requiredIf(function(){
          if(this.flagDebitCard){
            return true
          }else{
            return false
          }
        }),
      },
      expirationYear: {
        required: requiredIf(function(){
          if(this.flagDebitCard){
            return true
          }else{
            return false
          }
        }),
      }
    }
  },
  computed: {    
    totalPrice () {
      return this.itemFeatures.currencyCop * this.itemFeatures.quantity
    }
  },
  created() {
    this.loading = false
    this.itemFeatures = this.$store.getters.itemFeatures
    // console.log(this.itemFeatures)
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    this.fetchBanks()
    if(this.userProfile != null) {
      this.setUserData()
    }
  },
  methods: {
    // Google city
    getAddressData(addressData, placeResultData, id){
      // console.log(addressData)
      // console.log(placeResultData)
      let country = addressData.country
      // let locality = addressData.locality
      let fomatted_address = placeResultData.formatted_address
      // this.currencySelected = (country == 'Perú' || country  == 'Peru') ? 'PEN' : 'COP'
      // this.city = locality != null ? locality :  fomatted_address
      this.shipmentData.city = fomatted_address
      // this.$v.$touch()
    },
    cityDataNotFound(address){
      this.$v.shipmentData.city = null;
      // this.$v.$touch();
    },
    cityDataChanged(inputVal){
      if (inputVal.newVal != this.shipmentData.city){
          this.shipmentData.city = null;
      }
      this.$v.shipmentData.city.$touch()
      // this.$v.$touch();
    },
    cityBlur(){
      this.$v.shipmentData.city.$touch()
    }, 
    // END Google city
    setUserData(){
      // console.log(this.userProfile)
      this.shipmentData['fullname'] = this.userProfile.nombre
      this.shipmentData['city'] = this.userProfile.ciudad
      this.shipmentData['address'] = this.userProfile.direccion
      this.shipmentData['phone_number'] = this.userProfile.celular
      this.shipmentData['email'] = this.userProfile.email
    },
    logTabs(number) {
      switch (number) {
        case 0:
            this.flagDebitCard = false;
            this.flagPSE = true;
          break;
        case 1:
            this.flagDebitCard = true;
            this.flagPSE = false;
          break;
      
        default:
          break;
      }
    },
    fetchBanks(){
      let fetchBanks = this.$firebase.functions().httpsCallable('getBanks')
      fetchBanks().then((result) => {
        this.banks = result.data.data        
      }).catch((error) => {
        console.log(error)
      });

    },
    submit() {
      this.$v.$touch()
      if(this.flagPSE) {
        if(this.$v.paymentPSE.$invalid){
          console.log('falta completar datos pse')
        } else{
          console.log('paymentPSE')
          console.log(this.paymentPSE)          
          this.order('pse')
        }
      }else if(this.flagDebitCard){
        if(this.$v.paymentDebitCard.$invalid){
          console.log('falta completar datos tarjeta débito')
        } else{
          console.log('paymentDebitCard')
          console.log(this.paymentDebitCard)
          this.order('debitCard')
        }
      }
    },
    goTo (to) {
      if(to == 'terms'){
        let route = this.$router.resolve({path: '/pages/terms'});
        window.open(route.href, '_blank');
      }      
    },
    order(paymentMethod) {
      // console.log(this.itemFeatures)
      // console.log(this.shipmentData)
      let orderData = {
        model: this.itemFeatures.model,
        color: this.itemFeatures.color,
        size: this.itemFeatures.size,
        quantity: this.itemFeatures.quantity,
        currency: 'COP',
        currencyCop: this.itemFeatures.currencyCop,
        currenncyPen: null,
        serviceId: this.itemFeatures.id,

        userId: this.userProfile ? this.userProfile.id : null,
        fullname: this.shipmentData.fullname,
        city: this.shipmentData.city,
        address: this.shipmentData.address,
        email: this.shipmentData.email,
        phoneNumber: this.shipmentData.phone_number,
        paymentMethod
      };
      // console.log('orderData')
      // console.log(orderData)
      this.loading = true;
      let createOrder = this.$firebase.functions().httpsCallable('createOrder')
      createOrder(orderData).then((result) => {
        console.log(result.data.data)
        let data = result.data.data
        let orderId = null
        if(data != null) {
          orderId = data.id
          // console.log(orderId)          
          if(this.flagPSE){
            this.requestPSE(orderId)
          }else if(this.flagDebitCard){
            this.requestDebitCard(orderId)
          }
        }
      }).catch((error) => {
        console.log('ERROR ')
        console.log(error);
      });
    },
    requestPSE(orderId){
      console.log('requestPSE')
      let data = this.payloadPayment(orderId)
      let dataS = JSON.stringify(data) 
      console.log(data)
      const pseTransfer = this.$firebase.functions().httpsCallable('pseTransfer')
      pseTransfer(data).then((result) => {
        this.loading = false      
        let response = result.data.data
        let code = response.code
        let transactionResponse = response.transactionResponse
        let state = response.transactionResponse.state
        let responseCode = response.transactionResponse.responseCode
        if(code == 'SUCCESS'){
            console.log('transaction', transactionResponse)
            // DECLINED, ERROR, EXPIRED, PENDING, SUBMITTED
            if(state == 'PENDING' && responseCode == 'PENDING_TRANSACTION_CONFIRMATION'){                        
            let bankUrl = response.transactionResponse.extraParameters.BANK_URL
            console.log(bankUrl)
            // this.resetInfo()
            window.location.href = bankUrl
            }
            else if(state == 'DECLINED' || state == 'ERROR' || state == 'EXPIRED' || state == 'SUBMITTED'){
                // console.log('DECLINED', transactionResponse.paymentNetworkResponseErrorMessage)  
                this.flagTransactionFail = true     
                this.errMsg = 'La transacción no pudo ser llevada a cabo, intente de nuevo'               
            }                                        
        } 
      }).catch((error) => {
        console.log(error)
      })
    },
    requestDebitCard(orderId){
      console.log('requestDebitCard')
      let payload = this.payloadPayment(orderId, 'debitCard')      
      payload.debitCard = {
        "number": this.paymentDebitCard.cardNumber,
        "name": this.paymentDebitCard.cardName,
        "expirationDate": this.paymentDebitCard.expirationYear + '/' + this.paymentDebitCard.expirationMonth,
        "securityCode": this.paymentDebitCard.cvv
      }
      payload.paymentMethod = this.paymentDebitCard.cardSelected
      if(this.userCurrency == 'COP'){
        payload.paymentCountry = 'CO'
      }else if(this.userCurrency == 'PEN'){
        payload.paymentCountry = 'PE'
      }else if(this.userCurrency == 'ARP'){
        payload.paymentCountry = 'AR'
      }

      console.log(payload)

      let authAndCapture = this.$firebase.functions().httpsCallable('authAndCapture')
      authAndCapture(payload).then((response) => {
        this.loading = false        
                
        var result = response.data.data
        var error = result.error        
        var data = result.data
        console.log('error')
        console.log(error)
        console.log('data')
        console.log(data)

        if (error != null) {
          //Values of state different to APPROVED
          
          let transactionResponse = data.transactionResponse
          let state = transactionResponse.state
          console.log('state', state)          
          this.errMsg =  error.responseMessage

          if(state == 'ERROR' || state == 'DECLINED'){
            
            if(error.responseMessage == null || error.responseMessage == ''){
              this.errMsg =  'Transacción rechazada, intentar de nuevo' 
              this.flagTransactionFail = true
              this.scrollToTop( )  
              // this.resetConfidentialData()
            }
            else{
              this.errMsg = error.responseMessage
              this.flagTransactionFail = true   
            }
          }
          else if(state == 'PENDING'){
              console.log('transancción pendiente')
              this.$router.push('/services/payment_confirmation?referenceCode=' 
              + payload.referenceCode 
              + '&transactionId='+transactionResponse.transactionId
              + '&debitCard='+true)
          }

        } else {           
          console.log('APPROVED')
          let transactionResponse = data.transactionResponse 
          let code = data.code                                          
          let state = transactionResponse.state
          console.log('transactionResponse', transactionResponse)                                                      
          let responseCode = transactionResponse.responseCode
                              
          console.log('APPROVED')
          this.$router.push('/services/payment_confirmation?referenceCode=' 
          + payload.referenceCode 
          + '&transactionId='+transactionResponse.transactionId
          + '&debitCard='+true)
        }

      }).catch((error) => {
        this.loading = true;
        console.log(error);
      });

    },
    payloadPayment(orderId, paymentMethod=null){                       
      let referenceCode = 'orderId-' + orderId 
      
      let cantidad = this.itemFeatures.quantity
      let description = this.itemFeatures.name +', Cantidad: ' + cantidad                             
      let txValue = cantidad * this.itemFeatures.currencyCop                
      
      let userCurrency = 'COP'
      var additionalValues = null
      
      if(userCurrency == 'COP'){
        var iva = null
        iva = 0.19
        let txTax = (txValue * iva).toFixed(2)
        let txBase = (txValue - txTax).toFixed(2)
        additionalValues ={
          TX_VALUE: {
            "value": txValue,
            "currency": userCurrency
          },
          TX_TAX: {
            "value": txTax,
            "currency": userCurrency
          },
          TX_TAX_RETURN_BASE: {
            "value": txBase,
            "currency": userCurrency
          }
        } 
      }else if(userCurrency == 'PEN' || userCurrency == 'ARP'){
        additionalValues ={
          TX_VALUE: {
            "value": txValue,
            "currency": userCurrency
          }                    
        } 
      }
      
      let buyer= {
        "emailAddress": this.shipmentData.email
      }
      let payer =  {
        "fullName": this.shipmentData.fullname,
        "emailAddress": this.shipmentData.email,
        "contactPhone": this.shipmentData.phone_number
      }

      var payload = {
          referenceCode,
          bank: this.paymentPSE.bankSelected ? this.paymentPSE.bankSelected.value : null,                    
          clientType: this.paymentPSE.clientTypeSelected ? this.paymentPSE.clientTypeSelected : null,
          documentType: this.paymentPSE.typeDocSelected ? this.paymentPSE.typeDocSelected : null,
          // document: this.paymentPSE.numDocument,   
          description,
          additionalValues,
          currency: userCurrency,
          buyer,
          payer, 
      }
      // console.log(payload)
      if(paymentMethod == 'debitCard'){              
        const {clientType, ...newPayload} = payload
        newPayload['document'] = this.paymentDebitCard.numDocument
        console.log('document:', this.paymentDebitCard.numDocument)
        return newPayload
      }
      else{
        payload['document'] = this.paymentPSE.numDocument
        return payload
      }        
    },  
    scrollToTop() {
      window.scrollTo(0,0);
    },

  },
    
}
</script>

<style scoped src="vue-tel-input/dist/vue-tel-input.css">

</style>